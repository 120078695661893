import React from 'react';

import { BookDemoOverlay } from '@/features/new-landing-page/components/book-demo-overlay';

export function TalkToExpert() {
  const onClose = () => {
    const { referrer } = document;
    const myDomain = window.location.origin;

    if (referrer.startsWith(myDomain) && !referrer.includes(`talk-to-expert`)) {
      window.history.back();
    } else {
      window.location.href = `/`;
    }
  };

  return <BookDemoOverlay onClose={onClose} />;
}
